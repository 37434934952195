import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface ProgressProps {
  currentStep: number;
  totalSteps: number;
}

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 0.25rem;
  width: 100%;
`;

const ProgressBarContainer = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? '#804DA1' : '#fff')};
  border-radius: 50px;
  flex-grow: 1;
  height: 100%;
  transition: background-color 0.25s;
`;

const ResultsProgress: FC<ProgressProps> = ({ currentStep, totalSteps }) => (
  <Container>
    {Array.from({ length: totalSteps }, (_, i) => (
      <ProgressBarContainer key={i + 1} active={i + 1 <= currentStep} />
    ))}
  </Container>
);

export default ResultsProgress;
